(function($) {

    $(document).ready(function() {

        /**
         * Menu Toggle
         */
        var menuOpen = false;
        $('.js-menu-toggle').on('click', function(evt) {
            evt.preventDefault();

            $(this).toggleClass('isOpen');
            $('.js-nav-primary').toggleClass('isOpen');
            $('.js-site-head').toggleClass('isOpen');
        });

        $('.js-menu-close').on('click', function(evt) {
            evt.preventDefault();
            closeMenu();
        });


        function closeMenu() {
            $('.js-menu-toggle').removeClass('isOpen');
            $('.js-nav-primary').removeClass('isOpen');
            $('.js-site-head').removeClass('isOpen');
        }

        $(window).resize(function(){
            closeMenu();
        });

        $('.js-nav-primary a').on('click', function(){
          closeMenu();
        });



        /*
         * Alt Header on scroll
         */
        stickHeader();
        window.addEventListener('scroll', function(e){
            stickHeader();
        });

        function stickHeader() {
            var distanceY = window.pageYOffset || document.documentElement.scrollTop,
                shrinkOn = 50,
                header = $('.js-site-head');
            if (distanceY > shrinkOn) {
                header.addClass('isSticky');

            } else {
                if (header.hasClass('isSticky')) {
                    header.removeClass('isSticky');
                }
            }
        }


        /**
         * Accordion
         */
        $('.js-accordion-control').on('click', function(evt) {
            evt.preventDefault();

            var $tab = $(this).closest('.js-accordion-item');
            var $this = $(this);

            $tab.find('.accordion-body').toggleClass('isActive');
            $this.toggleClass('isActive');
        });


        /**
         *
         * SmoothScroll
         *
         */
        // $('a[href*="#"]:not([href="#"])').click(function() {
        //     if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
        //       var target = $(this.hash);
        //       target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
        //       if (target.length) {
        //         $('html, body').animate({
        //           scrollTop: target.offset().top - 80
        //         }, 1000);
        //         return false;
        //       }
        //     }
        //   });





        /**
         *
         * Slick
         *
         */
        $('.js-slick').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            autoplay: true,
            autoplaySpeed: 6000,
        });
            $('.js-slick-item-link').on('click', function(evt) {
                evt.preventDefault();
                $('.js-slick').slick('slickGoTo', $(this).data('slick-item'));
                $(this).addClass('isActive');
                $('.js-slick-item-link').not($(this)).removeClass('isActive');
            });
            // On before slide change
            $('.js-slick').on('beforeChange', function(event, slick, currentSlide, nextSlide) {
                $('.js-slick-item-link').removeClass('isActive');
                $('.js-slick-item-link[data-slick-item="'+nextSlide+'"]').addClass('isActive');
            });

        $('.js-slick-gallery').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            asNavFor: '.js-slick-thumbs',
            lazyLoad: 'ondemand'
        });
        $('.js-slick-thumbs').slick({
            slidesToShow: 10,
            slidesToScroll: 1,
            asNavFor: '.js-slick-gallery',
            dots: false,
            arrows: true,
            focusOnSelect: true,
            lazyLoad: 'ondemand',
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 1,
                        arrows: true
                    }
                }
            ]
        });

            $('.js-slick-filter').on('click', function(evt){
                evt.preventDefault();
                // get category
                var category = $(this).data('filter');
                // fade out gallery
                $('.js-slick-gallery').fadeOut(100);
                $('.js-slick-thumbs').fadeOut(100);
                // unfilter
                $('.js-slick-gallery').slick('slickUnfilter');
                $('.js-slick-thumbs').slick('slickUnfilter');
                // filter if not all
                if(category !== 'all') {
                    $('.js-slick-gallery').slick('slickFilter','.'+category);
                    $('.js-slick-thumbs').slick('slickFilter','.'+category);
                }
                // fade back in
                setTimeout(function(){
                    $('.js-slick-gallery').fadeIn(100);
                    $('.js-slick-thumbs').fadeIn(100);
                }, 100);
                // active menu state
                $('.js-slick-filter').removeClass('isActive');
                $(this).addClass('isActive');
            });

        $('.js-slick-milestone').slick({
            slidesToShow: 4,
            slidesToScroll: 1,
            arrows: false,
            adaptiveHeight: true,
            infinite: false,
            asNavFor: '.js-slick-milestone-list',
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 1023,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                }
            ]
        });
        $('.js-slick-milestone-list').slick({
            slidesToShow: 20,
            slidesToScroll: 1,
            asNavFor: '.js-slick-milestone',
            dots: false,
            focusOnSelect: true,
            infinite: false,
            arrows: true,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 1,
                        arrows: true
                    }
                },
                {
                    breakpoint: 1023,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 1,
                        arrows: true
                    }
                }
            ]
        });


        /**
         *
         * Modaal
         *
         */
        $('.js-modaal').modaal({
            width: 640,
            background: '#081b34',
            overlay_opacity: .8
        });

        $('.js-modaal-gallery').modaal({
            type: 'image',
            background: '#081b34',
            overlay_opacity: .8
        });


        $('.js-gallery-filter').on('click', function(evt) {
            evt.preventDefault();
            var category = $(this).data('filter');

            // fade out gallery
            $('.js-gallery-list').fadeOut(200);

            // fade back in
            setTimeout(function() {


                if(category !== 'all') {
                    $( ".js-gallery-list li" ).addClass('hidden').find('a').attr('rel', '');

                    $( ".js-gallery-list li" ).filter( '.'+category ).removeClass('hidden').find('a').attr('rel', 'gallery');

                } else {
                    $( ".js-gallery-list li" ).removeClass('hidden').find('a').attr('rel', 'gallery');
                }

                $('.js-gallery-list').fadeIn(200);

            }, 200);

            // active menu state
            $('.js-gallery-filter').removeClass('isActive');
            $(this).addClass('isActive');
        });


        /**
         *
         * AOS int
         *
         */
        // AOS.init({
        //     offset: 200,
        //     easing: 'ease-in-out-cubic'
        // });


        //videos module magic :)
        $('.video--placeholder, .video-trigger').on('click', function() {
            var videoType = $(this).attr('data-video-type');
            var video = $(this).attr('data-' + videoType);
            $('.' + videoType + '-video').addClass('active');
            $('#' + videoType).attr('src', video);
            $('.video-modal').fadeIn();
            setTimeout(function() {
                var videoWidth = $('.' + videoType + '-video figure').width();
                var videoHeight = ($('.' + videoType + '-video figure').width() * 0.5625);
                $('#' + videoType).css('width', videoWidth + 'px');
                $('#' + videoType).height(videoHeight);
            }, 500);
        });

        $('.video-modal').on('click', function(e) {
            $(this).fadeOut();
            $('.video').removeClass('active');
            $('#youtube').attr('src', '//www.youtube.com');
            $('#vimeo').attr('src', '//player.vimeo.com');
            $('iframe').height(0);
            $('iframe').width(0);
        });

        var $allVideos = $("iframe[src^='//player.vimeo.com'], iframe[src^='//www.youtube.com'], object, embed"),
        $fluidEl = $("figure");
        console.log($allVideos);

        $allVideos.each(function() {
            
        $(this)
        
            // jQuery .data does not work on object/embed elements
            .attr('data-aspectRatio', this.height / this.width)
            .removeAttr('height')
            .removeAttr('width');

            $(this).width($fluidEl.width());
            $(this).height($fluidEl.width() * $(this).attr('data-aspectRatio'));
        });
        
        $(window).resize(function() {
        

        var newWidth = $fluidEl.width();
        $allVideos.each(function() {

            var $el = $(this);
            $el
                .width(newWidth)
                .height(newWidth * $el.attr('data-aspectRatio'));

        });

        }).resize();

    });

    if ('addEventListener' in window) {
        window.addEventListener('resize', function(){
            sameHeights();
        });
        window.addEventListener('load', function(){
            sameHeights();
        });
    }

    function sameHeights(selector) {
        var selector = selector || '[data-key="sameHeights"]',
            query = document.querySelectorAll(selector),
            elements = query.length,
            max = 0;
        if (elements) {
            while (elements--) {
                var element = query[elements];
                if (element.clientHeight > max) {
                    max = element.clientHeight;
                }
            }
            elements = query.length;
            while (elements--) {
                var element = query[elements];
                element.style.height = max + 'px';
            }
        }
    }

})(jQuery); // Fully reference jQuery after this point.